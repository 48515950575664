export const environment = {
  production: false,
  baseUrl: '',
  version: '0.0.1',
  client: 'industry-network',
  secret: 'industry2network',
  googleKey: 'AIzaSyBlvEjVjLQNB0nhZzOYQ6dfoXR5NTN0AZg',
  fbLogin:'464484322951840',
  googleLogin: '96547190886-b099c48ch50q8a3fd4m8u5vk8tpgtjra.apps.googleusercontent.com',
  clientApproveDays: 3
};
